import React from 'react';
import Layout from 'components/Layout';
import Link from 'components/base/Link';
import classnames from 'classnames';

const ValueURLs = ({ pageContext }) => {
  const context = pageContext;
  return (
    <Layout pageTitle="Value URLs">
      <article className="container container--thin container--boxed">
        <ul className="list body-copy pa3 pa4-xl pa5-xxl">
          {context.data.map((item, index) => {
            return (
              <li
                key={index}
                className={classnames({
                  pt2: index !== 0
                })}
              >
                <Link to={item.url}>
                  {item.context.name}: {item.context.value}
                </Link>
              </li>
            );
          })}
        </ul>
      </article>
    </Layout>
  );
};

export default ValueURLs;
